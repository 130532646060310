var App = new Vue({
  el: '#app',
  data: {
    isMenuOpen: false,
    members: [],
    body: document.body,
    overlay: document.querySelector('#overlay'),
    header: document.querySelector('#header'),
    htmlEl: document.querySelector('html'),
    memberToggles: document.querySelectorAll('.person__toggle'),
    embedConsent: false,
    embedDialogActive: false,
    mainNavigation: [],
    embedDialog: [],
    keyHandle: [],
    tabHandle: [],
    disabledHandle: [],
    hiddenHandle: [],
    focusedElementBeforeOpened: [],
    currentContext: []
  },

  methods: {
    toggleMenu: function () {

      if (!this.isMenuOpen) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },

    openMenu: function () {
      this.isMenuOpen = true;
      this.currentContext = this.mainNavigation;
      this.defaultActionsOnOpen();
      this.body.classList.add('main-nav--open');
      this.body.scrollTop = 0;
      this.htmlEl.scrollTop = 0;
      this.overlay.addEventListener('click', this.triggerMenuToggle);
    },

    closeMenu: function () {
      this.isMenuOpen = false;
      this.currentContext = this.mainNavigation;
      this.defaultActionsOnClose();
      this.body.classList.remove('main-nav--open');
      this.overlay.addEventListener('click', this.triggerMenuToggle);
    },

    triggerMenuToggle: function () {
      this.header.scrollTop = 0;
      this.$refs.menuToggle.click();
    },

    gotoHome: function () {
      /** nothing todo here */
    },

    toggleEmbedConsent: function () {
      if (this.embedConsent) {
        this.removeEmbedConsent();
      } else {
        this.openEmbedDialog();
      }
    },

    defaultActionsOnOpen: function () {

      // Remember the focused element before we opened the dialog
      // so we can return focus to it once we close the dialog.
      this.focusedElementBeforeOpened = document.activeElement;

      // Make sure that no element outside of the dialog
      // can be interacted with while the dialog is visible.
      // This means we don't have to handle Tab and Shift+Tab,
      // but can defer that to the browser's internal handling.
      this.disabledHandle = ally.maintain.disabled({
        filter: this.currentContext,
      });

      // Make sure that no element outside of the dialog
      // is exposed via the Accessibility Tree, to prevent
      // screen readers from navigating to content it shouldn't
      // be seeing while the dialog is open. See example:
      // https://marcysutton.com/slides/mobile-a11y-seattlejs/#/36
      this.hiddenHandle = ally.maintain.hidden({
        filter: this.currentContext,
      });

      // Make sure that Tab key controlled focus is trapped within
      // the tabsequence of the dialog and does not reach the
      // browser's UI, e.g. the location bar.
      this.tabHandle = ally.maintain.tabFocus({
        context: this.currentContext,
      });

      // React to enter and escape keys as mandated by ARIA Practices
      this.keyHandle = ally.when.key({
        escape: this.closeDialogByKey,
      });

      // We're using a transition to reveal the dialog,
      // so wait until the element is visible, before
      // finding the first keyboard focusable element
      // and passing focus to it, otherwise the browser
      // might scroll the document to reveal the element
      // receiving focus
      var that = this;
      ally.when.visibleArea({
        context: that.currentContext,
        callback: function (context) {
          // the dialog is visible on screen, so find the first
          // keyboard focusable element (giving any element with
          // autofocus attribute precendence). If the dialog does
          // not contain any keyboard focusabe elements, focus will
          // be given to the dialog itself.
          var element = ally.query.firstTabbable({
            context: context, // context === dialog/mainNavigation
            defaultToContext: true,
          });
          element.focus();
        },
      });

    },

    defaultActionsOnClose: function () {
      // Undo hiding elements outside of the dialog/mainNavigation
      this.disabledHandle.disengage();
      this.hiddenHandle.disengage();
      this.tabHandle.disengage();
      this.keyHandle.disengage();

      // return focus to where it was before we opened the dialog
      this.focusedElementBeforeOpened.focus();
    },

    openEmbedDialog: function () {

      this.currentContext = this.embedDialog;

      // Accessibility Improvements: 
      // Hide outside Elements, Store Focus and Set Focus to Context
      this.defaultActionsOnOpen();

      // Show the dialog
      this.embedDialogActive = true;
    },

    closeEmbedDialog: function () {
      this.currentContext = this.embedDialog;
      this.defaultActionsOnClose();
      this.embedDialogActive = false;
    },

    closeDialogByKey: function () {
      this.closeEmbedDialog();
      this.closeMenu();
    },

    setEmbedConsent: function () {
      this.embedConsent = true;

      if (this.storageAvailable('localStorage')) {
        var timeInMs = new Date().getTime();
        localStorage.setItem('embedConsent', timeInMs);
      }
    },

    removeEmbedConsent: function () {
      this.embedConsent = false;

      if (this.storageAvailable('localStorage')) {
        localStorage.removeItem('embedConsent');
      }
    },

    storageAvailable: function (type) {
      var storage;
      try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          (storage && storage.length !== 0);
      }
    },

    checkEmbedConsent: function () {
      if (!this.storageAvailable('localStorage')) {
        return false;
      }

      var storageItem = localStorage.getItem('embedConsent');
      if (storageItem) {
        this.embedConsent = true;
      }
    },
  },

  beforeMount: function () {
    if (!this.memberToggles) return;

    var that = this;
    var toggles = this.memberToggles;

    for (var index = 0; index < toggles.length; index++) {
      (function () {
        that.members.push({
          open: true == toggles[index].attributes['data-open'].value ? true : false,
          id: toggles[index].attributes['data-id'].value
        });
      })(index);
    }

    this.checkEmbedConsent();
  },

  mounted: function () {
    // Define UI-Elements
    this.mainNavigation = document.querySelector('#main-navigation');
    this.embedDialog = document.querySelector('#embed-dialog');

  }

});

// Vue.config.devtools = true;

// You can also pass in the default options
/*
Vue.use(VueScrollTo, {
  container: "body",
  duration: 0,
  easing: "",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
*/